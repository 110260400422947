* {
  outline: none;
  // -ms-overflow-style: none !important;
  // /* IE and Edge */
  // scrollbar-width: none !important;

  // /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none !important;
  // }
}

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

div, span, p {
  caret-color: transparent;
}

body {
  margin: 0;
  box-sizing: border-box;
}

// body::-webkit-scrollbar {
//   width: 4px;
//   background: #0a0a0a
// }

// body::-webkit-scrollbar-track {
//   background: 0 0
// }

// body::-webkit-scrollbar-thumb {
//   background: #C43FE1;
//   border-radius: 16px;
// }

// body::-webkit-scrollbar-thumb:hover {
//   background: #C43FE1;
// }

#root {
  position: relative;
}

.app {
  font-family: "BlenderPro-Heavy";
  font-weight: 700;
  color: #FFFFFF;
  transition: all ease 0.33ms;
}

.font-BlenderPro-BoldItalic {
  font-family: 'BlenderPro-BoldItalic';
}

.font-BlenderPro-Bold {
  font-family: 'BlenderPro-Bold';
}

.font-BlenderPro-Book {
  font-family: 'BlenderPro-Book';
}

.font-BlenderPro-Medium {
  font-family: 'BlenderPro-Medium';
}

.font-BlenderPro-Heavy {
  font-family: 'BlenderPro-Heavy';
}

.ml-4 {
  margin-left: 4px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-32 {
  margin-left: 32px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-44 {
  margin-top: 44px;
}

.mt-48 {
  margin-top: 48px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-30 {
  font-size: 30px;
}

.font-32 {
  font-size: 32px;
}

.font-90 {
  font-size: 90px;
}

.font-96 {
  font-size: 96px;
}

.color1 {
  color: #FFFFFF;
}

.color2 {
  color: #AAAAAA;
}

.color3 {
  color: #000000;
}

.color4 {
  color: #06EEFF;
}

.color5 {
  color: #00EEFF;
}

.color6 {
  color: #9E9E9E;
}

.color7 {
  color: rgba(255, 255, 255, 0.8);
}

.color8 {
  color: #FF7B00;
}

.font-weight-b {
  font-variation-settings: "wght" 700;
  font-weight: bolder;
}

.font-weight-6 {
  font-weight: 600;
}

.font-weight-3 {
  font-weight: 300;
}

.border-b {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.border-radius-4 {
  border-radius: 4px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

@media only screen and (max-width: 1080px) {

  .font-96,
  .font-90 {
    font-size: 48px;
  }
}