.MuiAlert-root {
    width: 300px;
}
.MuiAlert-message {
    width: 100%;
    text-align: center;
    font-size: 15px;
}
.MuiAlert-action {
    display: none;
}