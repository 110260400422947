.header-view {
	position: fixed;
	top: 0;
	z-index: 1;
	height: 80px;
	width: 100vw;
	background: linear-gradient(180deg, #0D0D0D 0%, #0D0D0D 0%, rgba(13, 13, 13, 0.00) 100%, rgba(13, 13, 13, 0.00) 100%);

	.header {
		height: 100%;
		width: 100%;

		.header-l {
			margin-left: 16px;

			.header-menu {
				.header-menu-item {
					margin-left: 40px;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}

		.header-r {
			margin-right: 32px;

			.connect {
				margin-left: 26px;
				width: 110px;
				height: 35px;
				background: url(../../assets/images/connect.png);
				background-size: 100% 100%;
				background-repeat: no-repeat;
			}
		}
	}
}

@media only screen and (max-width: 1080px) {
	.header-view {
		.header {
			.header-l {
				margin-left: 20px;
			}

			.header-r {
				margin-right: 16px;

				.connect {
					margin-left: 0;
				}
			}
		}
	}
}